<template>
  <div>
    <shipblu-table
        :sst="true"
        multiple
        orders
        v-model="selected"
        pagination
        :max-items="maximumItems"
        :data="binLogs"
        :tableLoader="tableLoader"
      >
      <template slot="thead">
        <shipblu-th>{{$t('User')}}</shipblu-th>
        <shipblu-th>{{$t('Status')}}</shipblu-th>
        <shipblu-th>{{$t('Date')}}</shipblu-th>
        <shipblu-th>{{$t('WRO')}}</shipblu-th>
        <shipblu-th>{{$t('SKU')}}</shipblu-th>
        <shipblu-th>{{$t('No.of products')}}</shipblu-th>
        <shipblu-th>{{$t('Location')}}</shipblu-th>
      </template>

      <template slot-scope="{ data }">
        <shipblu-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data" :color="`${getStyle(data[indextr])}`">
          <shipblu-td :data="data[indextr].by">
            {{ data[indextr].by }}
          </shipblu-td>

          <shipblu-td :data="data[indextr].status" :style="`color: ${getStyle(data[indextr])}`" style="white-space: nowrap;">
            <div class="m-auto flex items-center justify-center font-semibold">
              <div><span class="status-icon mr-1"></span></div>
              <span class="capitalize">{{ $t(getStatusLabel(data[indextr])) }}</span>
            </div>
          </shipblu-td>

          <shipblu-td :data="data[indextr].created">
            <p v-if="data[indextr].created">{{ new Date(data[indextr].created).toLocaleDateString('fr-CA') }} {{ new Date(data[indextr].created).toLocaleTimeString() }}</p>
          </shipblu-td>

          <shipblu-td :data="data[indextr].wro">
            {{ data[indextr].extra_info ? data[indextr].extra_info.wro : 'N/A' }}
          </shipblu-td>

          <shipblu-td :data="data[indextr].sku">
            {{ data[indextr].extra_info ? data[indextr].extra_info.product.sku : 'N/A' }}
          </shipblu-td>

          <shipblu-td :data="data[indextr].number_of_products">
            {{ data[indextr].extra_info ? data[indextr].extra_info.number_of_products : 'N/A' }}
          </shipblu-td>

          <shipblu-td :data="data[indextr].location">
            {{ data[indextr].extra_info ? data[indextr].extra_info.location : 'N/A' }}
          </shipblu-td>

        </shipblu-tr>
      </template>
    </shipblu-table>
    <shipblu-pagination :maximumItems="maximumItems" :noOptions="true" :totalRows="totalRows" :currentPage="currentPage" @currentPage="currentPage = $event" />
  </div>
</template>

<script>
import ShipbluTable from '../../layouts/components/NewShipBluTable.vue'
import ShipbluTr from '../../layouts/components/ShipBluTr.vue'
import ShipbluTh from '../../layouts/components/ShipBluTh.vue'
import ShipbluTd from '../../layouts/components/ShipBluTd.vue'
import addBins   from './components/AddBins.vue'
import {sendFulfillmentRequest} from '../../http/axios/requestHelper.js'
import i18nData from '../../i18n/i18nData.js'
import common from '../../assets/utils/common'
import ShipbluPagination from '../../layouts/components/ShipbluPagination.vue'

export default {
  components: {
    ShipbluTable,
    ShipbluTr,
    ShipbluTh,
    ShipbluTd,
    addBins,
    ShipbluPagination
  },
  data () {
    return {
      maximumItems: process.env.VUE_APP_MAXIMUM_ITEMS_TABLE,
      totalRows: 0,
      selected: [],
      currentPage: (this.$route.query.page &&  Number(this.$route.query.page) > 0) ? Number(this.$route.query.page) : 1,
      offset: 0,
      tableLoader: false,
      binLogs: [
        {
          extra_info: {
            sku: ''
          }
        }
      ]
    }
  },
  methods: {
    getStyle (data) {
      return common.getOrderStatusColor(data.status)
    },
    getOrderStatusColor (order) {
      return common.getOrderStatusColor(this.getStatusLabel(order))
    },
    getStatusLabel (order) {
      return common.getStatusLabel(order, this.$store.state.AppActiveUser.userRole)
    },
    formatTime (time) {
      let hr = time.split(':')[0]
      const min = time.split(':')[1]
      const ampm = hr >= 12 ? i18nData[this.$i18n.locale]['pm'] : i18nData[this.$i18n.locale]['am']
      hr = hr > 12 ? hr -= 12 : hr
      return `${hr  }:${  min  }${ampm}`
    },
    removeAttribute (event) {
      event.preventDefault()
    },
    loadBinLogs () {
      this.tableLoader = true
      sendFulfillmentRequest(true, false, this, `api/v1/fc/bins/${this.$route.params.binID}/tracking/`, 'get', null, true,
        (response) => {
          this.binLogs = response.data.results
          this.totalRows = response.data.count
          this.searchedValue = this.searchVal
          this.tableLoader = false
        }
      )
    }
  },
  watch: {
    currentPage () {
      this.offset = (this.currentPage - 1) * this.maximumItems
      this.$router.push({
        query: {
          page: this.currentPage
        }
      }).catch(() => {})
      this.loadBinLogs()
    }
  },
  created () {
    this.offset = (this.currentPage - 1) * this.maximumItems
    this.loadBinLogs()
  }
  
}
</script>
<style lang="scss">
@import "@/assets/scss/vuexy/apps/email.scss";
.con-pagination-table{
  display: none;
}
</style>